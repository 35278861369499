<template>
  <GumInternalSelect
    :options="options"
    :multiple="false"
    :value="value"
    @update:value="handleUpdate"
    @search="(value) => emit('search', value)"
    :placeholder="placeholder"
    :allowClear="allowClear"
    :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
    class="gum-select"
  >
    <template v-slot:option="{ option }">
      <!-- prettier-ignore -->
      <slot name="option" :option="(option as T)">
        {{ option.labelForDropdown || option.label }}
      </slot>
    </template>
  </GumInternalSelect>
</template>

<!-- This component handles only single select -->
<script setup lang="ts" generic="T extends GumSelectOption">
import GumInternalSelect, { type GumSelectOption } from './GumInternalSelect.vue'

type Props = {
  options: T[]
  value?: string | null
  placeholder?: string | null
  allowClear?: boolean
  dropdownMatchSelectWidth?: boolean
}

withDefaults(defineProps<Props>(), { dropdownMatchSelectWidth: true, allowClear: true })

const emit = defineEmits<{
  (e: 'update:value', value?: string | undefined | null): void
  (e: 'search', value: string): void
}>()

const handleUpdate = (value: string[] | string | null | undefined) => {
  if (Array.isArray(value)) {
    throw 'Wrong value! Something is wrong with your code'
  }

  emit('update:value', value)
}
</script>
