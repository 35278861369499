<template>
  <AntSelect
    :allowClear="allowClear"
    :disabled="formState?.disabled || disabled"
    :filterOption="filterOptions"
    :loading="loading"
    :notFoundContent="loading ? undefined : 'Not records found'"
    :options="loading ? [] : options"
    showSearch
    :value="value || undefined"
    @search="handleSearch"
    @update:value="handleSelect"
    class="gum-select"
    :mode="multiple ? 'multiple' : undefined"
    :placeholder="placeholder"
    :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
  >
    <template v-if="loading" #notFoundContent> Loading... </template>
    <!-- #menuItemSelectedIcon slot exists but the types is wrong -->
    <!-- @vue-ignore -->
    <template #menuItemSelectedIcon><CheckIcon /></template>
    <template #suffixIcon><ArrowIcon class="rotate-90 -mt-[1px] opacity-60" /></template>
    <template #option="item">
      <slot name="option" :option="item">
        <div class="flex flex-col">
          <span>{{ item.labelForDropdown || item.label }}</span>
          <span v-if="item.label2">{{ item.label2 }}</span>
          <span v-if="item.label3">{{ item.label3 }}</span>
        </div>
      </slot>
    </template>
  </AntSelect>
</template>

<!-- This is an internal component. DON'T USE IT DIRECTLY -->
<script setup lang="ts">
import AntSelect, { type SelectValue } from 'ant-design-vue/es/select'
import { ArrowIcon, CheckIcon } from '../icons'
import { debounce } from 'lodash-es'
import { useFormState } from './global-state'

const formState = useFormState()

export type GumSelectOption = {
  value: string
  label: string
  label2?: string
  label3?: string
  options?: GumSelectOption[]
  searchLabel?: string
  labelForDropdown?: string
}
type Props = {
  mode?: 'autocomplete' | 'normal'
  options: GumSelectOption[]
  loading?: boolean
  allowClear?: boolean
  disabled?: boolean
  multiple?: boolean
  value: string[] | string | null | undefined
  placeholder?: string | null
  dropdownMatchSelectWidth?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'normal',
  multiple: false,
  loading: false,
  allowClear: true,
  disabled: false,
  dropdownMatchSelectWidth: true
})

const emit = defineEmits<{
  (e: 'update:value', value?: string[] | string | null | undefined): void
  (e: 'search', value: string): void
}>()

const handleSearch = debounce((value) => {
  emit('search', value)
}, 300)

const handleSelect = (value: SelectValue) => {
  if (props.multiple) {
    if (!Array.isArray(value)) {
      throw 'Invalid value. Something is wrong with your code!'
    }

    emit(
      'update:value',
      value.map((val) => (typeof val === 'object' ? val.value.toString() : val.toString()))
    )
  } else {
    if (Array.isArray(value)) {
      throw 'Invalid value. Something is wrong with your code!'
    }
    if (typeof value === 'object') {
      value = value.value
    }

    emit('update:value', value?.toString() || null)
  }
}

const includes = (a: string, b: string): boolean => {
  return a.toLocaleLowerCase().includes(b.toLocaleLowerCase())
}

const filterOptions = (input: string, option: GumSelectOption | undefined) => {
  if (props.mode === 'autocomplete') {
    return true
  }

  if (option?.searchLabel) {
    return includes(option.searchLabel, input)
  }

  if (option?.labelForDropdown) {
    return includes(option.labelForDropdown, input)
  }

  if (option?.label2) {
    return includes(option.label2, input)
  }

  if (option?.label3) {
    return includes(option.label3, input)
  }

  return includes(option?.label || '', input)
}
</script>

<style lang="less" scoped>
.gum-select {
  &:deep(.ant-select) {
    min-width: 5.5rem !important;
  }

  &:deep(.ant-select-selection-placeholder) {
    color: #bfbfbf !important;
  }
}

.gum-select.ant-select-disabled:deep(.ant-select-selector) {
  @apply bg-opacity-5 bg-black;
}

.gum-select.ant-select-disabled:deep(.ant-select-selector .ant-select-selection-item) {
  @apply text-opacity-88 text-black;
}
</style>
