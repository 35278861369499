<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8121 3.71094H16.4468C16.2554 3.71094 16.0738 3.79883 15.9566 3.94922L7.90388 14.1504L4.04255 9.25781C3.98414 9.18365 3.90969 9.12368 3.82478 9.08241C3.73987 9.04114 3.64672 9.01965 3.55232 9.01953H2.18708C2.05622 9.01953 1.98396 9.16992 2.06404 9.27148L7.41365 16.0488C7.66365 16.3652 8.14411 16.3652 8.39607 16.0488L17.9351 3.96094C18.0152 3.86133 17.9429 3.71094 17.8121 3.71094Z"
      :fill="COLORS[color]"
    />
  </svg>
</template>
<script setup lang="ts">
const COLORS = {
  blue: '#1677FF',
  white: '#fffff'
}

withDefaults(defineProps<{ color?: 'white' | 'blue' }>(), { color: 'blue' })
</script>
